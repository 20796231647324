import schttp from 'public/src/services/schttp'
import { getQueryString } from '@shein/common-function'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { getFeedbackRecInSession } from 'public/src/pages/product_list_v2/js/utils'

function getParams() {
  const attr_ids = getQueryString({ key: 'attr_ids' })
  const { ymal, similar, goodsId } = getFeedbackRecInSession() || {}
  return { attr_ids, ymal, similar, goodsId }
}


// 信息流落地页点后推
export async function getRecGoodsInfo({ scene_id = 252, goodsId, productLimit = 0, jsonRuleId, triggerEvent, isClick, isAddbag, location = '', exposedGoodsId = '', atomFields, atomCccParams, isPaid = false, atomScene = {}, abtInfoV3 }, requestInstance = null) {
  let resultGoods = []
  const data = {
    scene_id: scene_id,
    location: location,
    triggerEvent: triggerEvent,
    jsonRuleId: jsonRuleId,
    limit: productLimit + 2, 
    page_enable: 0,
    // 过滤售罄
    filterSoldOutConfig: {
      on: true,
    },
    contextParams: {
      'top_goods_id': goodsId, // 商品ID (落地页未点击时传入口图，落地页有点击传点击商品）(不做置顶)
      'is_click': isClick || 0, // 用户落地页是否有点击      0:无点击  1:有点击
      'is_addbag': isAddbag || 0, // 用户落地页是否有加车   0:无  1:有
      'exposed_goods_id': exposedGoodsId // 信息流和落地页已曝光商品list,逗号分隔，推荐依据该入参做去重过滤
    },
    atomFields: {
      detailImage: 1,
      stock: true,
      prices: true,
      sheinClubPromotionInfo: true,
      newFlashPromotion: true,
      flashZonePromotion: true,
      estimatedPrice: 1,
      ...atomFields,
    },
    atomCccParams: atomCccParams,
    isPaid: isPaid,
    newPreHandle: true,
    atomScene: atomScene,
    abtInfoV3: {
      newProductCard: true,
      ...abtInfoV3,
    },
  }
  // requestInstance?.abort()
  // !requestInstance && (requestInstance = new SchttpAbortCon())
  const result = await schttp({
    method: 'POST',
    url: '/api/recommend/facadeAtom/get',
    signal: requestInstance.signal,
    data,
  })
  resultGoods = result?.info?.products?.slice(0, productLimit) || []
  // resultGoods = xx?.slice(0, productLimit) || []
  // 自加逻辑，标识是点后推商品类型
  resultGoods?.forEach(item=> {
    item.type = 'recommend-for-you'
  })
  return resultGoods
}



// 需求：pageId=1195316198 的 BFF接口， 返回点后推动态插坑商品
export async function getRecGoodsInfoBFF({ pageSelectId, pageCateId, discountRate, salePrice, language, goodsId, productLimit = 0, isPaid = false, cateId, addCart, exposedGoodsId, reportIndex }, requestInstance = null) {
  const { attr_ids, ymal, similar } = getParams()
  const result = await schttp({
    method: 'GET',
    url: '/product/recommend/feedback_products',
    signal: requestInstance.signal,
    params: {
      goods_id: goodsId,
      filter_goods_yaml: ymal ? `${exposedGoodsId},${ymal}` : exposedGoodsId,
      filter_goods_similar: similar ?? '',
      goods_discount: discountRate,
      goods_price: salePrice,
      goods_cate_id: cateId,
      filter: attr_ids ?? '',
      limit: 10 || productLimit,
      scene: '2',
      triggerEvent: 'NotJudged', // 群里中间层确定的固定写死这个值
      add_cart: addCart,
      main_select_id: pageSelectId,
      main_cate_id: pageCateId
    },
    useBffApi: true,
    // headers: {
    //   'sheindebug': 676,
    //   'Content-Type': 'multipart/form-data',
    // },
  })

  // 过滤售罄商品展示
  const isSoldOut = (item) => +item.stock === 0 || +item.is_on_sale === 0
  let goods = result?.info?.products.filter(item => !isSoldOut(item)) || []
  let goodList = (goods || []).map(v => {
    return goodsTransformation(v, {
      language,
      lang: gbCommonInfo.lang ?? 'us',
      isPaid: !!isPaid
    })
  })
  // 标识点后推商品
  goodList?.forEach((item, index)=> {
    item.type = 'recommend-for-you'
    item.realtimePosition = `list_realtime_position_${reportIndex + 1}_${index + 1}` // 为了解决点推个数为两个时，list_realtime_position_ 埋点字段一个要为1 一个要为2
    item.reportIndex = reportIndex
  })
  return goodList
}
